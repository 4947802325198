import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume, faAt } from "@fortawesome/free-solid-svg-icons";

import "../css/footer.css";
import "../css/font.css";

function Footer() {
  return (
    <Container fluid>
      <Row>
        <Col className="phone_footer" xs={12} sm={12} md={6}>
          <FontAwesomeIcon icon={faPhoneVolume} className="phone_icon_footer" />

          <p className="phone_number_footer text-center">
            <a href="tel:9811882656">9 8 1 1 8 8 2 6 5 6</a>
          </p>
          <p className="phone_number_footer text-center">
            <a href="tel:01294011402">0 1 2 9 - 4 0 1 1 4 0 2</a>
          </p>
        </Col>
        <Col className="email_footer" xs={12} sm={12} md={6}>
          <p className="text-center" style={{ width: "100%" }}>
            <FontAwesomeIcon icon={faAt} className="email_icon_footer" />
          </p>

          <p
            className="text-center email_address_footer"
            style={{ width: "100%" }}
          >
            <a href="mailto:kopal.mjain@gmail.com">
              k o p a l . m j a i n @ g m a i l . c o m
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
export default Footer;
