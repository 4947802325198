import React from "react";
import { Container, Row, ListGroup } from "react-bootstrap";
import Layout from "../Layout";
import pedicureVideo from "../../assets/pedicure/pedicure.mp4";
import "../../css/font.css";
import "../../css/skin-services-details/meni-padi.css";

function PedicureManicure() {
  return (
    <Layout>
      <Container fluid className="master_container--pedicure">
        <Row>
          <video
            id="pedicure_videoTag"
            autoPlay
            muted
            loop
            playsInline
            className="video-js"
            preload="auto"
            poster="//vjs.zencdn.net/v/oceans.png"
            data-setup="{}"
          >
            <source src={pedicureVideo} type="video/mp4"></source>
            {/* <source src={homeVideowebm} type="video/webm"></source>
                <source src={homeVideoogg} type="video/ogg"></source> */}
            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
              >
                supports HTML5 video
              </a>
            </p>
          </video>
        </Row>
        <Row>
          <h4 className="pedi_header--h4">A L L &nbsp; P A C K A G E S</h4>
        </Row>
        <Row>
          <ListGroup className="meni_padi_listgroup--mobileView">
            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Fruit Manicure</p>
              <p className="facial_package_price_pedi ">Rs 400 </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Fruit Pedicure</p>
              <p className="facial_package_price_pedi ">Rs 500</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Lotus delux Manicure</p>
              <p className="facial_package_price_pedi ">Rs 800</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Lotus delux Pedicure</p>
              <p className="facial_package_price_pedi ">Rs 900</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">
                Lotus crystal Manicure
              </p>
              <p className="facial_package_price_pedi ">Rs 900</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">
                Lotus crystal Pedicure
              </p>
              <p className="facial_package_price_pedi ">Rs 1000</p>
            </ListGroup.Item>
          </ListGroup>
          {/*  List Group for LS */}
          <ListGroup horizontal className="meni_padi_listgroup--ls">
            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Fruit Manicure</p>
              <p className="facial_package_price_pedi ">Rs 400 </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Fruit Pedicure</p>
              <p className="facial_package_price_pedi ">Rs 500</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Lotus delux Manicure</p>
              <p className="facial_package_price_pedi ">Rs 800</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Lotus delux Pedicure</p>
              <p className="facial_package_price_pedi ">Rs 900</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">
                Lotus crystal Manicure
              </p>
              <p className="facial_package_price_pedi ">Rs 900</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">
                Lotus crystal Pedicure
              </p>
              <p className="facial_package_price_pedi ">Rs 1000</p>
            </ListGroup.Item>
          </ListGroup>
        </Row>
      </Container>
    </Layout>
  );
}

export default PedicureManicure;
