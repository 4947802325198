import React from "react";
import Layout from "../Layout";
import { Container, ListGroup, Row } from "react-bootstrap";
import body_spaVideo from "../../assets/spa/body_spa.mp4";
import body_spaVideoOgg from "../../assets/spa/body_spa.ogg";
import body_spaVideoWebm from "../../assets/spa/body_spa.webm";

import "../../css/font.css";
import "../../css/skin-services-details/spa.css";
function BodySpa() {
  return (
    <Layout>
      <Container fluid className="master_container--spa">
        <Row>
          <video
            id="pedicure_videoTag"
            autoPlay
            muted
            loop
            playsInline
            className="video-js"
            preload="auto"
            poster="//vjs.zencdn.net/v/oceans.png"
            data-setup="{}"
          >
            <source src={body_spaVideo} type="video/mp4"></source>
            <source src={body_spaVideoOgg} type="video/ogg"></source>
            <source src={body_spaVideoWebm} type="video/webm"></source>

            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
              >
                supports HTML5 video
              </a>
            </p>
          </video>
        </Row>
        <Row>
          <h4 className="spa_header--h4">A L L &nbsp; P A C K A G E S</h4>
        </Row>
        <Row>
          {/* List Group for Mobile Screen */}
          <ListGroup className="spa_listgroup--mobileView">
            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Aroma Oil Spa</p>
              <p className="facial_package_names_pedi ">Time: 1 hour 40 min</p>
              <p className="facial_package_price_pedi ">Rs 3500 </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Body Polishing</p>
              <p className="facial_package_names_pedi ">
                (includes scrubbing and creme massage only)
              </p>
              <p className="facial_package_names_pedi ">Time: 50 min</p>
              <p className="facial_package_price_pedi ">Rs 2500</p>
            </ListGroup.Item>
          </ListGroup>
          {/* List Group for large screen */}
          <ListGroup horizontal className="spa_listgroup--ls">
            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Aroma Oil Spa</p>
              <p className="facial_package_names_pedi ">Time: 1 hour 40 min</p>
              <p className="facial_package_price_pedi ">Rs 3500 </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <p className="facial_package_names_pedi ">Body Polishing</p>
              <p className="facial_package_names_pedi ">
                (includes scrubbing and creme massage only)
              </p>
              <p className="facial_package_names_pedi ">Time: 50 min</p>
              <p className="facial_package_price_pedi ">Rs 2500</p>
            </ListGroup.Item>
          </ListGroup>
        </Row>
      </Container>
    </Layout>
  );
}
export default BodySpa;
