import React from "react";
import { Card, Container, Row, Col, ListGroup, Image } from "react-bootstrap";
import Layout from "../Layout";
import o3_facial_mp4 from "../../assets/facial-packages/o3_facials.mp4";
import casmaraFacialImage from "../../assets/facial-packages/casmara_facial.jpg";
import lotusFacialImage from "../../assets/facial-packages/lotus_facials.jpg";
import "../../css/skin-services-details/facials.css";

function Facials() {
  return (
    <Layout>
      <Container fluid className="master_container--facial_services">
        <Row className="master_container--facial_services--1stRow">
          <h5 className="master_container--facial_services--h5">
            F A C I A L S - M B K &nbsp; &nbsp;L U X U R Y
          </h5>
        </Row>
        <Row>
          <Col className="facial_services--col " xs={12} sm={12} md={4}>
            <Card className="facial_service_card">
              <Card.Title className="facial_service_card--title">
                <h5>F A C I A L S &nbsp; B Y </h5>
                <h4>O3</h4>
              </Card.Title>
              <Card.Body className="facial_service_card--body">
                <div>
                  <video
                    id="facial_videotag"
                    autoPlay
                    muted
                    playsInline
                    loop
                    className="video-js"
                    preload="auto"
                    poster="//vjs.zencdn.net/v/oceans.png"
                    data-setup="{}"
                  >
                    <source src={o3_facial_mp4} type="video/mp4"></source>
                    {/* <source src={homeVideowebm} type="video/webm"></source>
                    <source src={homeVideoogg} type="video/ogg"></source> */}
                    <p className="vjs-no-js">
                      To view this video please enable JavaScript, and consider
                      upgrading to a web browser that
                      <a
                        href="https://videojs.com/html5-video-support/"
                        target="_blank"
                      >
                        supports HTML5 video
                      </a>
                    </p>
                  </video>
                </div>
                <p className="facial_service_card--body__para">
                  O3 have a record of 30 years of research and is very widely
                  used product across world.
                </p>
              </Card.Body>
              <Card.Footer className="facial_service_card--footer">
                <h5 className="facial_service_card--footer__h5">
                  P O P U L A R &nbsp; P A C K A G E S
                </h5>

                {/* This list group will be visible only in mobile view */}
                <ListGroup className="facial_service_listgroup--mobileview">
                  <ListGroup.Item>
                    <p className="facial_package_names">Basic Cleanup</p>
                    <p className="facial_package_price">Rs 1000</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <p className="facial_package_names">Facial</p>
                    <p className="facial_package_price">Rs 2000</p>
                  </ListGroup.Item>
                </ListGroup>

                {/* This list group will be visible only in large screen view */}
                <ListGroup
                  horizontal
                  className="facial_service_listgroup--lsview"
                >
                  <ListGroup.Item>
                    <p className="facial_package_names">Basic Cleanup</p>
                    <p className="facial_package_price">Rs 1000</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <p className="facial_package_names">Facial</p>
                    <p className="facial_package_price"> Rs 2000</p>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Footer>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={4} className="facial_services--col">
            <Card className="facial_service_card">
              <Card.Title className="facial_service_card--title">
                <h5>F A C I A L S &nbsp; B Y </h5>
                <h4>Casmara</h4>
              </Card.Title>
              <Card.Body className="facial_service_card--body">
                <Image fluid src={casmaraFacialImage} />
                <p className="facial_service_card--body__para">
                  Casmara products have been there in the market for last 45
                  years now. They are extremely good, safe and give good
                  results. We highly recommend our Casmara facials to our
                  customers depending on their skin condition.
                </p>
              </Card.Body>
              <Card.Footer className="facial_service_card--footer">
                <h5 className="facial_service_card--footer__h5">
                  P O P U L A R &nbsp; P A C K A G E S
                </h5>
                <Row>
                  {/* List Group -- Mobile View only */}

                  <ListGroup className="facial_service_listgroup--mobileview">
                    <ListGroup.Item>
                      <p className="facial_package_names">Vegetable Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Gozi Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Acne Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Sensitive Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Gold Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Green Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>
                  </ListGroup>

                  {/* List Group Large Screen only */}
                  <ListGroup
                    horizontal
                    className="facial_service_listgroup--lsview"
                  >
                    <ListGroup.Item>
                      <p className="facial_package_names">Vegetable Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Gozi Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Acne Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>
                  </ListGroup>
                </Row>
                <Row>
                  <ListGroup
                    horizontal
                    className="facial_service_listgroup--lsview"
                  >
                    <ListGroup.Item>
                      <p className="facial_package_names">Sensitive Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Gold Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p className="facial_package_names">Green Mask</p>
                      <p className="facial_package_price">Rs 3000</p>
                    </ListGroup.Item>
                  </ListGroup>
                </Row>
              </Card.Footer>
            </Card>
          </Col>

          <Col xm={12} sm={12} md={4} className="facial_services--col my-auto">
            <Card className="facial_service_card">
              <Card.Title className="facial_service_card--title">
                <h5>F A C I A L S &nbsp; B Y </h5>
                <h4>Lotus</h4>
              </Card.Title>
              <Card.Body className="facial_service_card--body">
                <Image src={lotusFacialImage} fluid />
                <p className="facial_service_card--body__para">
                  Lotus herbal products, as the name suggests, are very safe to
                  use. They have a large range of products for practically every
                  type of skin condition. Lotus products are most widely used in
                  India.
                </p>
              </Card.Body>
              <Card.Footer className="facial_service_card--footer">
                <h5 className="facial_service_card--footer__h5">
                  P O P U L A R &nbsp; P A C K A G E S
                </h5>
                {/* List Group - Mobile Screen */}
                <ListGroup className="facial_service_listgroup--mobileview">
                  <ListGroup.Item>
                    <p className="facial_package_names">Basic Cleanup</p>
                    <p className="facial_package_price">Rs 850 ++</p>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <p className="facial_package_names">Insta Fair</p>
                    <p className="facial_package_price">Rs 1800</p>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <p className="facial_package_names">Gold Sheen</p>
                    <p className="facial_package_price">Rs 2000</p>
                  </ListGroup.Item>
                </ListGroup>

                {/* List group - Large Screen */}
                <ListGroup
                  horizontal
                  className="facial_service_listgroup--lsview"
                >
                  <ListGroup.Item>
                    <p className="facial_package_names">Basic Cleanup</p>
                    <p className="facial_package_price">Rs 850 ++</p>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <p className="facial_package_names">Insta Fair</p>
                    <p className="facial_package_price">Rs 1800</p>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <p className="facial_package_names">Gold Sheen</p>
                    <p className="facial_package_price">Rs 2000</p>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default Facials;
