import React from "react";
import { Container, Row, Col, Image, ListGroup } from "react-bootstrap";
import Layout from "../../Components/Layout";
import hairTreatments from "../../assets/hair-services/hair_treatments.jpg";
import hairColour from "../../assets/hair-services/hair_colour.jpg";
import "../../css/font.css";
import "../../css/hair-service-details/hair.css";

function HairServices() {
  return (
    <Layout>
      <Container
        className="p-0 p-md-1"
        style={{ backgroundColor: "#3f000f", marginTop: "60px" }}
      >
        <Row className="m-0 p-0">
          <Col xs={12} sm={12} md={6} className="p-0 p-md-1">
            <Image fluid src={hairTreatments} />
            {/* This section will remain hidden in large view but will be visible in mobile view */}

            <ListGroup className="vertical_listgroup_hair--service">
              <ListGroup.Item>
                <p>Shine Bond Rs 5000 onwards</p>
              </ListGroup.Item>

              <ListGroup.Item>
                <p>Kera Smooth Rs 4000 onwards</p>
              </ListGroup.Item>

              <ListGroup.Item>
                <p>Keratin Rs 3000 onwards</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Olapex Rs 2500 onwards</p>
              </ListGroup.Item>

              <ListGroup.Item>
                <p>Olapex with Hair Spa Rs 3500 onwards</p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs={12} sm={12} md={6} className="p-0 p-md-1">
            <Image fluid src={hairColour} />
            {/* This section will remain hidden in large view but will be visible in mobile view */}
            <ListGroup className="vertical_listgroup_hair--service">
              <ListGroup.Item>
                <p>Highlights Rs 300/streak</p>
              </ListGroup.Item>

              <ListGroup.Item>
                <p>Global Basic Rs 2500 onwards</p>
              </ListGroup.Item>

              <ListGroup.Item>
                <p>Fashion Shades Rs 4000 onwards</p>
              </ListGroup.Item>

              <ListGroup.Item>
                <p>Balayage Rs 4000 onwards</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Ombre Rs 5000 onwards</p>
              </ListGroup.Item>

              <ListGroup.Item>
                <p>Touch up Roots Majirel</p>
                <p>Rs 800 onwards</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Touch up Roots Inoa</p>
                <p>Rs 1000 onwards</p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>

      {/* This container displays Packages */}
      <Container className="mt-md-3">
        <Row className="m-0 p-0">
          <Col xs={12} sm={12} md={6} className="package_col--hair_services">
            <h4>Hair Treatments</h4>
            <Row>
              <ListGroup
                horizontal
                className="horizontal_listgroup--hair_services"
              >
                <ListGroup.Item>
                  <p>Shine Bond</p>
                  <p>Rs 5000 onwards </p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <p>Kera Smooth</p>
                  <p>Rs 4000 onwards</p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <p>Keratin</p>
                  <p>Rs 3000 onwards</p>
                </ListGroup.Item>
              </ListGroup>
            </Row>
            <Row>
              <ListGroup
                horizontal
                className="horizontal_listgroup--hair_services"
              >
                <ListGroup.Item>
                  <p>Olapex</p>
                  <p>Rs 2500 onwards</p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <p>Olapex with Hair Spa</p>
                  <p>Rs 3500 onwards</p>
                </ListGroup.Item>
              </ListGroup>
            </Row>
          </Col>
          <Col className="package_col--hair_services">
            <h4>Hair Colour</h4>
            <Row>
              <ListGroup
                horizontal
                className="horizontal_listgroup--hair_services"
              >
                <ListGroup.Item>
                  <p>Highlights</p>
                  <p>Rs 300/streak </p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <p>Global Basic</p>
                  <p>Rs 2500 onwards</p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <p>Fashion Shades</p>
                  <p>Rs 4000 onwards</p>
                </ListGroup.Item>
              </ListGroup>
            </Row>
            <Row>
              <ListGroup
                horizontal
                className="horizontal_listgroup--hair_services"
              >
                <ListGroup.Item>
                  <p>Balayage</p>
                  <p>Rs 4000 onwards</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p>Ombre</p>
                  <p>Rs 5000 onwards</p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <p>Touch up Roots Majirel</p>
                  <p>Rs 800 onwards</p>
                </ListGroup.Item>
              </ListGroup>
            </Row>
            <Row>
              <ListGroup
                horizontal
                className="horizontal_listgroup--hair_services"
              >
                <ListGroup.Item>
                  <p>Touch up Roots Inoa</p>
                  <p>Rs 1000 onwards</p>
                </ListGroup.Item>
              </ListGroup>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default HairServices;
