import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Layout from "../Layout";
import waxImage1 from "../../assets/waxing-packages/waxing-packages-1.jpg";
import waxImage2 from "../../assets/waxing-packages/rica-wax.jpg";

import "../../css/skin-services-details/waxing.css";

function Waxing() {
  return (
    <Layout>
      <Container fluid>
        <Row className="mt-3 mb-5">
          <h5
            className="text-center"
            style={{
              width: "100%",
              fontFamily: "Eczar sans-serif",
              fontSize: "1.6rem",
            }}
          >
            Explore All Waxing Packages
          </h5>
        </Row>
        <Row className="mb-5 ">
          <Col xs={12} sm={12} md={6}>
            <section className="waxing--section1">
              <Image fluid src={waxImage1} />
            </section>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <section className="waxing--section2">
              <Image fluid src={waxImage2} />
            </section>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default Waxing;
