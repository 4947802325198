import React from "react";
import { Container, Row, Image } from "react-bootstrap";
import "../css/about.css";
import "../css/font.css";
import Layout from "../Components/Layout";
import meetKopalImage from "../assets/about us/meet_kopal_ls_view.jpg";
// import meet_kopal_mobile_view from "../assets/about us/meet_kopal_mobile_view.jpg";
import meet_kopal_mobile_view from "../assets/about us/IMG_1720.jpg";
function About() {
  return (
    <Layout>
      <Container fluid="md" className="master_container__about-section">
        <Row>
          <div className="about--section__2">
            <Image src={meetKopalImage} fluid className="about--image_MD" />
            <Image
              src={meet_kopal_mobile_view}
              fluid
              className="about--image_XS"
            />
          </div>
        </Row>

        <Row>
          <div className="about--section__3">
            <p>
              Makeup By Kopal is a venture of famous Makeup Artist Kopal Jain,
              who has been in the industry for a span of 7 years now. Makeup By
              Kopal is a one-stop destination for all your hair, skin and makeup
              requirements. Kopal is a highly accomplished makeup artist. She
              has created so many stunning looks for her brides, models as well
              as various celebrities. Her work has been featured in leading
              magazines. Driven by the vision and inspired by beauty, Kopal
              tends to focus on soft and natural makeup. She loves photography
              also and she knows how to do makeup according to photography also
              so that her bride always gets beautiful pictures. Kopal believes
              in detailing and she always make sure that even the tiniest
              elements are tuned to the perfection. Kopal beleives in much more
              detailed interaction with the clients, hence she always pitches
              her clients to go for a one-on-one consulation session before
              actual event. During such a session, they both discuss every
              element of their look in great depth such as dress details, hair
              styling details, jewellary designs etc. During the session Kopal
              also suggests the right skin enhancement treatments to the client,
              which the client may undergo before actual event. Such a healthy
              discussion helps team MBK to keep things well planned and
              organised on the day of event. Kopal also has her own line of
              eye-lashes. Kopal has a large variety of human hair made eye
              lashes. She often uses them on customers which are getting
              makeover from MBK salon, but of course anyone interested in buying
              the eye lashes can procure it from the salon.
            </p>
          </div>
        </Row>
      </Container>
    </Layout>
  );
}
export default About;
