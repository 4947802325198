import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume, faAt } from "@fortawesome/free-solid-svg-icons";
import Layout from "../Components/Layout";
import "../css/contact.css";
import "../css/font.css";

import contactUsImageMobile from "../assets/contact/contact-us__mobile_view.jpg";

function Contact() {
  return (
    <Layout>
      <Container className="master_container--contact-us">
        <Row>
          <Image
            src={contactUsImageMobile}
            fluid
            className="contact-us-Mobile"
          />
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} className="contact-us__col--phone">
            <h5 className="contact-us__col--phoneIcon">
              <FontAwesomeIcon icon={faPhoneVolume} />
            </h5>
            <p className="contact-us__col--phoneNumber">
              <a href="tel:9811882656">9 8 1 1 8 8 2 6 5 6</a>
            </p>
            <p className="contact-us__col--phoneNumber">
              <a href="tel:01294011402">0 1 2 9 - 4 0 1 1 4 0 2</a>
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} className="contact-us__col--email">
            <h5 className="contact-us__col--emailIcon">
              <FontAwesomeIcon icon={faAt} />
            </h5>
            <p className="contact-us__col--emailAddress">
              <a href="emailto:kopal.mjain@gmail.com">kopal.mjain@gmail.com</a>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default Contact;
