import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import Layout from "../Components/Layout";
import { useHistory } from "react-router-dom";
import preBridalPackageImage from "../assets/skin-services/pre-bridal-package-image.jpg";
import waxingImage from "../assets/skin-services/waxing.jpg";
import facialImage from "../assets/skin-services/facials.jpg";
import pedicureManicure from "../assets/skin-services/pedicure_manicure.jpg";
import spaImage from "../assets/skin-services/spa.jpg";
import bleachThreadingImage from "../assets/skin-services/bleach_threading.jpg";
import "../css/skin.css";
import "../css/font.css";
function Skin() {
  //Router components
  const history = useHistory();
  return (
    <Layout>
      <Container fluid className="master_container--services">
        <Row className="services--header__row">
          <h4 className="services--header__h4">
            S K I N &nbsp; S E R V I C E S
          </h4>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4} className="services-skin__col">
            <Image
              fluid
              src={preBridalPackageImage}
              onClick={() => {
                history.push({
                  pathname: "/services/skin/pre-bridal",
                });
              }}
              className="services-skin__col--img"
            />
            <p className="services-skin__col--p">
              For customer convenience we have grouped the commonly used
              services into easily usable packages. Our packages are totally
              flexibile and can be customised based on customer requirements as
              well as the preference of product brand by customer. Some of our
              popular packages are displayed below.
            </p>
            <Button
              variant="outline-dark"
              className="services-skin__col--btn"
              onClick={() => {
                history.push({
                  pathname: "/services/skin/pre-bridal",
                });
              }}
            >
              E X P L O R E
            </Button>
          </Col>

          <Col xs={12} sm={12} md={4} className="services-skin__col">
            <Image
              fluid
              src={waxingImage}
              onClick={() => {
                history.push({
                  pathname: "/services/skin/waxing",
                });
              }}
              className="services-skin__col--img"
            />
            <p className="services-skin__col--p">
              MBK offers a very comfortable waxing experience to its clients
              offering all kinds of waxing services using a variety of waxes.
              Our highly trained team ensures there are no skin burns or any
              other complications during waxing.
            </p>
            <Button
              variant="outline-dark"
              className="services-skin__col--btn"
              onClick={() => {
                history.push({
                  pathname: "/services/skin/waxing",
                });
              }}
            >
              E X P L O R E
            </Button>
          </Col>

          <Col xs={12} sm={12} md={4} className="services-skin__col">
            <Image
              fluid
              src={facialImage}
              onClick={() => {
                history.push({
                  pathname: "/services/skin/facials",
                });
              }}
              className="services-skin__col--img"
            />
            <p className="services-skin__col--p">
              MBK offers a delightful facial experience using state of the art
              products. Based on skin type, we have a large variety of products,
              and various kinds of facials. Some of our popular treatments are
              displayed below.
            </p>
            <Button
              variant="outline-dark"
              className="services-skin__col--btn"
              onClick={() => {
                history.push({
                  pathname: "/services/skin/facials",
                });
              }}
            >
              E X P L O R E
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={4} className="services-skin__col">
            <Image
              fluid
              src={pedicureManicure}
              onClick={() => {
                history.push({ pathname: "/services/skin/pedicure-manicure" });
              }}
              className="services-skin__col--img"
            />
            <p className="services-skin__col--p">
              Come and visit MBK salon to pamper your feet and hands with most
              luxurious pedicure and manicure experience.
            </p>
            <Button
              variant="outline-dark"
              className="services-skin__col--btn"
              onClick={() => {
                history.push({ pathname: "/services/skin/pedicure-manicure" });
              }}
            >
              E X P L O R E
            </Button>
          </Col>

          <Col xs={12} sm={12} md={4} className="services-skin__col">
            <Image
              fluid
              src={spaImage}
              onClick={() => {
                history.push({ pathname: "/services/skin/body-spa" });
              }}
              className="services-skin__col--img"
            />
            <p className="services-skin__col--p">
              Come and visit MBK salon to pamper your feet and hands with most
              luxurious pedicure and manicure experience.
            </p>
            <Button
              variant="outline-dark"
              className="services-skin__col--btn"
              onClick={() => {
                history.push({ pathname: "/services/skin/body-spa" });
              }}
            >
              E X P L O R E
            </Button>
          </Col>

          <Col xs={12} sm={12} md={4} className="services-skin__col">
            <Image
              fluid
              src={bleachThreadingImage}
              className="services-skin__col--img"
            />
            <p className="services-skin__col--p">
              Come and visit MBK salon to pamper your feet and hands with most
              luxurious pedicure and manicure experience.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default Skin;
