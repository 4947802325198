import React from "react";

//MobileScreen Imports

import homeVideoMobile from "../assets/home-video-new/video-ls-compressed.mp4";
//conversions pending

import homeVideowebmMobile from "../assets/home-video-new/video-ls-compressed.webm";
import homeVideooggMobile from "../assets/home-video-new/video-ls-compressed.ogg";

import ourWorkMakeupImage from "../assets/Home page pics/Our-work-makeup.jpg";
import ourWorkHairImage from "../assets/Home page pics/our-work-hair.jpg";
import ourWorkSkinImage from "../assets/Home page pics/Our-work-skin.jpg";

import posterImage from "../assets/Loading-poster.jpg";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import Layout from "../Components/Layout";
import { useHistory } from "react-router-dom";
import Enquire from "../Components/enquire";
import Footer from "../Components/Footer";

import "../css/home.css";
import "../css/font.css";

function Home() {
  //Route constants
  const history = useHistory();

  return (
    <Layout>
      <Container fluid className="container-main--home">
        <video
          id="my-player-mobile-screen"
          autoPlay
          muted
          loop
          className="video-js"
          preload="auto"
          playsInline
          poster={posterImage}
          data-setup="{}"
        >
          <source src={homeVideoMobile} type="video/mp4"></source>
          <source src={homeVideowebmMobile} type="video/webm"></source>
          <source src={homeVideooggMobile} type="video/ogg"></source>
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">
              supports HTML5 video
            </a>
          </p>
        </video>
      </Container>
      <Container fluid className="p-0 p-sm-0 p-md-1">
        <Row className="pl-1 pr-1 pt-0 pb-0 p-sm-1 p-md-2 m-0">
          <h4 className="home_our_work_h4">Our Work</h4>
          <p className="home_our_work_p">
            Here is a glimpse of what MBK salon offers to their clients. For
            Academy/courses{" "}
            <span
              onClick={() => {
                history.push({ pathname: "/academy" });
              }}
            >
              Click here
            </span>
          </p>
        </Row>

        <Container fluid="md" className="p-1 m-1 mx-auto">
          <Row className="m-0 p-1">
            <Col xs={12} sm={4} className="m-0 p-1 text-center">
              <Card className="mt-0 mb-4 p-2 p-sm-1 p-md-3">
                <Card.Title
                  className="text-center p-2 p-md-2 m-0 "
                  style={{
                    fontFamily: "Playfair Display, sans-serif",
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/services/makeup",
                    });
                  }}
                >
                  {" "}
                  M A K E U P
                </Card.Title>
                <Card.Body className="text-center p-0 p-md-2 mt-1 mb-1">
                  <Image
                    fluid
                    style={{ width: "100%" }}
                    src={ourWorkMakeupImage}
                    onClick={() => {
                      history.push({
                        pathname: "/services/makeup",
                      });
                    }}
                  ></Image>
                </Card.Body>
                <Card.Footer className="text-center p-0 p-md-2">
                  <Button
                    variant="outline-dark"
                    style={{ width: "100%", fontSize: "0.9rem" }}
                    onClick={() => {
                      history.push({
                        pathname: "/services/makeup",
                      });
                    }}
                  >
                    E X P L O R E
                  </Button>
                </Card.Footer>
              </Card>
            </Col>

            {/* Hair Col */}
            <Col xs={12} sm={4} className="m-0 p-1 text-center">
              <Card className="mt-0 mb-4 p-2 p-sm-1 p-md-3">
                <Card.Title
                  className="text-center p-2 p-md-2 m-0 "
                  style={{
                    fontFamily: "Playfair Display, sans-serif",
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/services/hair",
                    });
                  }}
                >
                  {" "}
                  H A I R
                </Card.Title>
                <Card.Body className="text-center p-0 p-md-2 mt-1 mb-1">
                  <Image
                    fluid
                    style={{ width: "100%" }}
                    src={ourWorkHairImage}
                    onClick={() => {
                      history.push({
                        pathname: "/services/hair",
                      });
                    }}
                  ></Image>
                </Card.Body>
                <Card.Footer className="text-center p-0 p-md-2">
                  <Button
                    variant="outline-dark"
                    style={{ width: "100%", fontSize: "0.9rem" }}
                    onClick={() => {
                      history.push({
                        pathname: "/services/hair",
                      });
                    }}
                  >
                    E X P L O R E
                  </Button>
                </Card.Footer>
              </Card>
            </Col>

            {/* Skin Section Col */}

            <Col xs={12} sm={4} className="m-0 p-1 text-center">
              <Card className="mt-0 mb-4 p-2 p-sm-1 p-md-3">
                <Card.Title
                  className="text-center p-2 p-md-2 m-0 "
                  style={{
                    fontFamily: "Playfair Display, sans-serif",
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/services/skin",
                    });
                  }}
                >
                  {" "}
                  S K I N
                </Card.Title>
                <Card.Body className="text-center p-0 p-md-2 mt-1 mb-1">
                  <Image
                    fluid
                    style={{ width: "100%" }}
                    src={ourWorkSkinImage}
                    onClick={() => {
                      history.push({
                        pathname: "/services/skin",
                      });
                    }}
                  ></Image>
                </Card.Body>
                <Card.Footer className="text-center p-0 p-md-2">
                  <Button
                    variant="outline-dark"
                    style={{ width: "100%", fontSize: "0.9rem" }}
                    onClick={() => {
                      history.push({
                        pathname: "/services/skin",
                      });
                    }}
                  >
                    E X P L O R E
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Enquire Section for Home Page */}
      <Enquire></Enquire>

      {/* Footer */}
      <Footer></Footer>
    </Layout>
  );
}
export default Home;
