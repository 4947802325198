import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import makeupImage from "../assets/services/makeup-service.jpg";
import skinImage from "../assets/services/skin-service.jpg";
import hairImage from "../assets/services/hair-mbk.jpg";
import Layout from "./Layout";
import "../css/services.css";
import { useHistory } from "react-router-dom";
function Services() {
  const history = useHistory();
  return (
    <Layout>
      <Container fluid className="master_container--services">
        <Row>
          <Col xs={12} sm={12} md={4} className="service_col">
            <Image
              src={makeupImage}
              fluid
              className="service_Image"
              onClick={() => {
                history.push({
                  pathname: "/services/makeup",
                });
              }}
            />
            <Button
              variant="dark"
              className="service_button"
              onClick={() => {
                history.push({
                  pathname: "/services/makeup",
                });
              }}
            >
              E X P L O R E
            </Button>
          </Col>

          <Col xs={12} sm={12} md={4} className="service_col">
            <Image
              src={skinImage}
              fluid
              className="service_Image"
              onClick={() => {
                history.push({
                  pathname: "/services/skin",
                });
              }}
            />
            <Button
              variant="dark"
              className="service_button"
              onClick={() => {
                history.push({
                  pathname: "/services/skin",
                });
              }}
            >
              E X P L O R E
            </Button>
          </Col>

          <Col xs={12} sm={12} md={4} className="service_col">
            <Image
              src={hairImage}
              fluid
              className="service_Image"
              onClick={() => {
                history.push({
                  pathname: "/services/hair",
                });
              }}
            />
            <Button
              variant="dark"
              className="service_button"
              onClick={() => {
                history.push({
                  pathname: "/services/hair",
                });
              }}
            >
              E X P L O R E
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default Services;
