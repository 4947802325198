import React from "react";
import { useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import "../css/font.css";
import "../css/academy.css";
import Enquire from "../Components/enquire";
function Academy() {
  //useState constants
  const [readMorePackage1, setReadPackageMore1] = useState(false);
  const [readMorePackage2, setReadPackageMore2] = useState(false);
  const [readMorePackage3, setReadPackageMore3] = useState(false);
  const [enquireModal, setEnquireModal] = useState(false);

  const closeModalEnquire = () => {
    setEnquireModal(false);
  };
  return (
    <Layout>
      <Container fluid className="master_container--academy">
        <Row className="academy__header--row">
          <h5>M B K - A L L &nbsp; C O U R S E S</h5>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4}>
            <Card className="academy_course--card">
              <Card.Title className="academy_course--card--title">
                <h5>15 Days Professional Makeup Course</h5>
                <h4> with Senior Educator</h4>
                <p>
                  Course Fee : Rs 35,000/-
                  <br></br>
                  Booking Amount : Rs 15,000/-
                  <br></br>
                  Course Duration : 15 Days
                  <br></br>
                  Location : Makeup By Kopal Salon & Academy, Faridabad NCR
                </p>
              </Card.Title>
              <Card.Body className="academy_course--card--body">
                DAY 1: Skin Prep & prime / colour correction/concealing &
                foundation application Product knowledge / colour wheel theory /
                different skin types, face shapes & eye types
                <p
                  onClick={() => setReadPackageMore1(!readMorePackage1)}
                  style={{
                    marginTop: "5px",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {readMorePackage1 === true
                    ? "Click to Hide..."
                    : "Click to Read More..."}
                </p>
                {readMorePackage1 === true ? (
                  <div>
                    DAY 2: Practice on each other
                    <br></br>
                    <br></br>
                    DAY 3: Skin + powder (contour + blush + highlight)
                    application + eyebrows + smokey liner with 2 colours
                    graduation + lash application + makeup do’s & don’t’s
                    <br></br>
                    <br></br>
                    DAY 4: Practice on each other
                    <br></br>
                    <br></br>
                    DAY 5: Skin + Powder & Cream (Contour + blush + highlight)
                    application + eyebrows + soft smokey eye makeup + lash
                    application
                    <br></br>
                    <br></br>
                    Day 6: Practice on each other
                    <br></br>
                    <br></br>
                    Day 7: Full face makeup with coloured smokey eyes + pigments
                    + lash application
                    <br></br>
                    <br></br>
                    Day 8: Practice on each other
                    <br></br>
                    <br></br>
                    Day 9: Full face makeup with soft cut crease +
                    gliter/pigments + lash application
                    <br></br>
                    <br></br>
                    Day 10: Practice on each other
                    <br></br>
                    <br></br>
                    Day 11: Full face makeup with halo eyes, glitter / pigments
                    + lash application
                    <br></br>
                    <br></br>
                    Day 12: Practice on each other
                    <br></br>
                    <br></br>
                    Day 13: Full bridal makeup with half cut crease, glitter /
                    pigments + lash application
                    <br></br>
                    <br></br>
                    Day 14: Practice on each other
                    <br></br>
                    <br></br>
                    Day 15: Test / certificate / portfolio shoot (bring your own
                    model)
                  </div>
                ) : (
                  ""
                )}
              </Card.Body>
              <Card.Footer className="academy_course--card--footer">
                <Button
                  variant="outline-dark"
                  onClick={() => setEnquireModal(true)}
                >
                  E N Q U I R E
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={4}>
            <Card className="academy_course--card">
              <Card.Title className="academy_course--card--title">
                <h5>4 Days Air Brush Course</h5>
                <p>
                  Course Fee : Rs 30,000/-
                  <br></br>
                  Booking Amount : Rs 15,000/-
                  <br></br>
                  Course Duration : 4 Days
                  <br></br>
                  Location : Makeup By Kopal Salon & Academy, Faridabad NCR
                </p>
              </Card.Title>
              <Card.Body className="academy_course--card--body">
                DAY 1: Airbrush Demo with Kopal Jain (full face makeup)
                <p
                  onClick={() => setReadPackageMore2(!readMorePackage2)}
                  style={{
                    marginTop: "5px",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {readMorePackage2 === true
                    ? "Click to Hide..."
                    : "Click to Read More..."}
                </p>
                {readMorePackage2 === true ? (
                  <div>
                    DAY 2: Practice on each other
                    <br></br>
                    <br></br>
                    DAY 3: Practice on each other
                    <br></br>
                    <br></br>
                    DAY 4: PRACTICE (on each other) & ASSESSMENT
                    <br></br>
                    <br></br>
                  </div>
                ) : (
                  ""
                )}
              </Card.Body>
              <Card.Footer className="academy_course--card--footer">
                <Button
                  variant="outline-dark"
                  onClick={() => setEnquireModal(true)}
                >
                  E N Q U I R E
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={4}>
            <Card className="academy_course--card">
              <Card.Title className="academy_course--card--title">
                <h5>22 Days Professional Makeup Course </h5>
                <h4>with Kopal</h4>

                <p>
                  (including 4 days of Air Brush Training)
                  <br></br>
                  Course Fee : Rs 1,35,000/-
                  <br></br>
                  Booking Amount : Rs 50,000/-
                  <br></br>
                  Course Duration : 22 Days
                  <br></br>
                  Location : Makeup By Kopal Salon & Academy, Faridabad NCR
                </p>
              </Card.Title>
              <Card.Body className="academy_course--card--body">
                Step by Step detailed course map.
                <br></br>
                Preparing the face for makeup.
                <br></br>
                Skin types and its analysis.
                <p
                  onClick={() => setReadPackageMore3(!readMorePackage3)}
                  style={{
                    marginTop: "5px",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {readMorePackage3 === true
                    ? "Click to Hide..."
                    : "Click to Read More..."}
                </p>
                {readMorePackage3 === true ? (
                  <div>
                    Perfecting the skin with Molsturizer and Primer.
                    <br></br>
                    <br></br>
                    Application of Concealer for all skin type.
                    <br></br>
                    <br></br>
                    Product usage, knowledge and recommendations.
                    <br></br>
                    <br></br>
                    Tools hygiene. Application of Blush.
                    <br></br>
                    <br></br>
                    Highlighting and shading. Basic and winged – Eyeliner.
                    <br></br>
                    <br></br>
                    How to get perfect Eyeliner & how to correct the crooked
                    one.
                    <br></br>
                    <br></br>
                    Application of Kohl and how to make it last longer. How to
                    correct Eye Brows.
                    <br></br>
                    <br></br>
                    Simple / Party full face makeup.
                    <br></br>
                    <br></br>
                    Lipstick application and recommendations.
                    <br></br>
                    <br></br>
                    Simple and intense smokey eyes.
                    <br></br>
                    <br></br>
                    Bridal makeup.
                    <br></br>
                    <br></br>
                    Glitter Application. Application of False Lashes.
                    <br></br>
                    <br></br>
                    Lash curling and application of Mascara. Dramatic Makeup
                    using Scotch Tape.
                    <br></br>
                    <br></br>
                    Airbrush makeup demo. (No self practice. Demo given by Kopal
                    only).
                    <br></br>
                    <br></br>
                    Fashion and Vintage makeup. Hair Styling - Two looks Bridal
                    <br></br>
                    <br></br>
                    Chunni and Saree draping
                    <br></br>
                    <br></br>
                    Portfolio day (Bring your own model)
                    <br></br>
                    <br></br>
                    Assessment day(Bring your own Model)
                    <br></br>
                    <br></br>
                    <h5
                      style={{
                        textDecoration: "underline",
                        textAlign: "center",
                      }}
                    >
                      Course Completion Certification duly Signed by Kopal
                    </h5>
                    <br></br>
                    <br></br>
                    <ul>
                      <h5>
                        Students who enroll for 22 days course will get goodies
                        worth Rs 8500:
                      </h5>
                      <li>Two Eye shadow pallete</li>
                      <li>Eyes and Face Brushes set</li>
                      <li>Two pair of MBK Eye lashes</li>
                    </ul>
                    <ul>
                      <h5>
                        Students who have enroll for 22 days courses will have
                        to get the following:
                      </h5>
                      <li>A beauty blender, preferably of Real Technique.</li>
                      <li>Black & Brown pencils.</li>
                      <li>Maybelline gel liner.</li>
                      <li>
                        Any eye makeup remover (Maybelline, Nuetrogena,
                        Sephora).
                      </li>
                      <li>
                        We will only be providing single eye shadows for
                        practice. Incase you wish to work with a palette request
                        you to carry your own.
                      </li>
                      <li>Big box of cotton tips.</li>
                      <li>
                        2/3 pairs of lashes light / medium / heavy made out of
                        human hair. Please not get plastic lashes.
                      </li>
                      <li>Volumising mascara (loreal)</li>
                      <li>
                        Complimentary Photoshoot and guidance in creating your
                        portfolio.
                      </li>
                    </ul>
                    <br></br>
                    <br></br>
                    <p style={{ fontStyle: "italic" }}>
                      NOTE: Classes shall be held in a professional makeup
                      studio environment with a balance of demonstration and
                      hands on practical work. You will practice with a
                      classmate therefore you musty be willing to have makeup
                      applied on you as well as applying it to others. We will
                      provide you with makeup products to practice with in class
                      also (these products remain the property of the studio) At
                      the completion of course students may be asked to undergo
                      a practical test in which they will have to arrange a
                      model for performing makeup.
                    </p>
                    <ul>
                      <h5>Important</h5>
                      <li>
                        Students will have to buy the airbrush machine if you
                        wish to practice airbrush skills.
                      </li>
                      <li>
                        If you do not wish to buy the machine you can continue
                        to practice manual makeup skills.
                      </li>
                      <li>
                        Students can get their friends or relatives on portfolio
                        days. We will also be sharing the contact details of the
                        model’s post enrolment. The charges of the models vary
                        between Rs. 1500/- to Rs. 3000/-. The students will be
                        entirely responsible for clothes and jewellery.
                      </li>
                      <li>
                        Two best students of the Professional makeup course get
                        to intern for a period of 10 days with Kopal at the
                        studio when we have bridal appointments. They might also
                        get a chance to do a “junior artist Makeup” if the need
                        arises. Also we are always recommending our students to
                        brides to be who are looking for makeup services at
                        their doorstep for their smaller functions or for their
                        family.
                      </li>
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </Card.Body>
              <Card.Footer className="academy_course--card--footer">
                <Button
                  variant="outline-dark"
                  onClick={() => setEnquireModal(true)}
                >
                  E N Q U I R E
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={enquireModal} onHide={closeModalEnquire} size="lg">
        <Modal.Body>
          <Enquire />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => setEnquireModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
export default Academy;
