import React from "react";
import { Card, Container, Row, Col, Image } from "react-bootstrap";
import Layout from "../Layout";
import preBridalPackage1 from "../../assets/pre-bridal-packages/pre-bridal-package1.jpg";
import preBridalPackage2 from "../../assets/pre-bridal-packages/pre-bridal-package2.jpg";
import deluxBridalImage from "../../assets/pre-bridal-packages/delux-pre-bridal.jpg";
import "../../css/font.css";
import "../../css/skin-services-details/pre-bridal.css";
function PreBridal() {
  return (
    <Layout>
      <Container fluid className="master_container--pre-bridal">
        <Row className="master_container--pre-bridal__header-row">
          <h5 className="pre-bridal__h5">
            P R E &nbsp; B R I D A L &nbsp;P A C K A G E S
          </h5>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4}>
            <Card className="pre-bridal__packageCard">
              <Card.Title className="pre-bridal__packageCard--title">
                <h5>Premium Package</h5>
                <h4>Rs 2900 only</h4>
              </Card.Title>
              <Card.Body className="pre-bridal__packageCard--body">
                <Image src={preBridalPackage2} fluid />
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={4}>
            <Card className="pre-bridal__packageCard--recommended">
              <Card.Title className="pre-bridal__packageCard--recommended--title">
                <h5>Deluxe Pre-Bridal Package </h5>
                <h4>Rs 21,850 only</h4>
              </Card.Title>
              <Card.Body className="pre-bridal__packageCard--recommended--body">
                <Image src={deluxBridalImage} fluid />
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={4} className="my-auto">
            <Card className="pre-bridal__packageCard">
              <Card.Title className="pre-bridal__packageCard--title">
                <h5>Basic Pre-Bridal Package </h5>
                <h4>Rs 7550 only</h4>
              </Card.Title>
              <Card.Body className="pre-bridal__packageCard--body">
                <Image src={preBridalPackage1} fluid />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default PreBridal;
