import React from "react";

//importing react-router-dom functions
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Makeup from "./Components/Makeup";
import Skin from "./Components/Skin";
import PreBridal from "./Components/skin-services-details/pre-bridal";
import Waxing from "./Components/skin-services-details/waxing";
import Facials from "./Components/skin-services-details/facials";
import PedicureManicure from "./Components/skin-services-details/mani_pedi";
import BodySpa from "./Components/skin-services-details/spa";
import HairServices from "./Components/hair-services-detail/hair-services";
import Services from "./Components/Services";
import Academy from "./Components/academy";
import "./css/app.css";
function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/academy" component={Academy} />
      <Route exact path="/services/makeup" component={Makeup} />
      <Route exact path="/services/skin" component={Skin} />
      <Route exact path="/services/skin/waxing" component={Waxing} />
      <Route exact path="/services/skin/pre-bridal" component={PreBridal} />
      <Route exact path="/services/skin/facials" component={Facials} />
      <Route exact path="/services/skin/body-spa" component={BodySpa} />
      <Route exact path="/services/hair" component={HairServices} />

      <Route
        exact
        path="/services/skin/pedicure-manicure"
        component={PedicureManicure}
      />
    </Router>
  );
}

export default App;
