import React from "react";
import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";
import Layout from "./Layout";
import bridalMakeupByKopalImage from "../assets/makeup-services-pics/BRIDAL HD MAKEUP BY KOPAL.png";
import bridalMakeupBySeniorImage from "../assets/makeup-services-pics/BRIDAL HD MAKEUP BY SENIOR.png";
import bridalAirBrushMakeupByKopalImage from "../assets/makeup-services-pics/air-brush-makeup-bridal-kopal.jpg";
import bridalAirBrushMakeupBySeniorImage from "../assets/makeup-services-pics/air-brush-makeup-bridal-senior.jpg";

import "../css/font.css";
import "../css/makeup-services.css";

function Makeup() {
  return (
    <Layout>
      <Container className="master_container_makeup_services">
        <Row>
          <h4 className="makeup_services_header_h4">HD Makeup</h4>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} className="img_col_makeup--service">
            <Image
              fluid
              src={bridalMakeupByKopalImage}
              className="makeupservice_image"
            />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Card className="service_description_card">
              <Card.Title className="service_description_card--title">
                <h5>HD Bridal Makeup By Kopal</h5>
                <h4>Rs 21,500 /-</h4>
              </Card.Title>
              <Card.Body className="service_description_card--body">
                We use following brands in this category of Makeup:
                <br></br>( Chanel, Bobbie Brown, Huda Beauty, Beverlyhills,
                Makeup Forever, )<br></br>
                This category of Makeup offers following services:
                <br></br>
                MINK EYELASHES(worth Rs 1200) , BRIDAL DRAPING, NAIL PAINT ,
                JEWELLERY SETTING
              </Card.Body>
              {/* <Card.Footer className="service_description_card--footer">
                <Button variant="outline-dark">View Pictures</Button>
                <Button variant="outline-dark">Enquire</Button>
              </Card.Footer> */}
            </Card>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          {/* This column will remain hidden in big screen but will become visible in mobile view */}
          <Col xs={12} sm={12} md={6} className="optional_column_top ">
            <Image
              fluid
              src={bridalMakeupBySeniorImage}
              className="makeupservice_image"
            />
          </Col>

          <Col xs={12} sm={12} md={6}>
            <Card className="service_description_card">
              <Card.Title className="service_description_card--title">
                <h5>HD Bridal Makeup by Senior Artist</h5>
                <h4>Rs 15,000 /-</h4>
              </Card.Title>
              <Card.Body className="service_description_card--body">
                We use following brands in this category of Makeup: <br></br>(
                INGLOT, SEPHORA, LA GIRL, MORPHE ) <br></br>This category of
                Makeup offers following services: <br></br>MINK EYELASHES WORTH
                (Rs 750)BRIDAL DRAPING, NAIL PAINT, JEWELLERY SETTING
              </Card.Body>
              {/* <Card.Footer className="service_description_card--footer">
                <Button variant="outline-dark">View Pictures</Button>
                <Button variant="outline-dark">Enquire</Button>
              </Card.Footer> */}
            </Card>
          </Col>
          {/* This column will remain visible in big screen but will become hidden in mobile view */}
          <Col xs={12} sm={12} md={6} className="optional_column_bottom">
            <Image
              fluid
              src={bridalMakeupBySeniorImage}
              className="makeupservice_image"
            />
          </Col>
        </Row>
      </Container>

      {/* This container displays Air Brush Makeup Category */}
      <Container className="master_container_makeup_services_2">
        <Row>
          <h4 className="makeup_services_header_h4">Air Brush Makeup</h4>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} className="img_col_makeup--service">
            <Image
              fluid
              src={bridalAirBrushMakeupByKopalImage}
              className="makeupservice_image"
            />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Card className="service_description_card">
              <Card.Title className="service_description_card--title">
                <h5>Air Brush Bridal Makeup By Kopal</h5>
                <h4>Rs 24,500 /-</h4>
              </Card.Title>
              <Card.Body className="service_description_card--body">
                We use Temptu brand in this category of Makeup: <br></br>This
                category of Makeup offers following services: <br></br> MINK
                EYELASHES( worth Rs 1500), BRIDAL DRAPING, NAIL PAINT ,
                JEWELLERY SETTING
              </Card.Body>
              {/* <Card.Footer className="service_description_card--footer">
                <Button variant="outline-dark">View Pictures</Button>
                <Button variant="outline-dark">Enquire</Button>
              </Card.Footer> */}
            </Card>
          </Col>
        </Row>
        <hr></hr>

        <Row>
          <Col xs={12} sm={12} md={6} className="optional_column_top">
            <Image
              fluid
              src={bridalAirBrushMakeupBySeniorImage}
              className="makeupservice_image"
            />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Card className="service_description_card">
              <Card.Title className="service_description_card--title">
                <h5>Air Brush Bridal Makeup by Senior Artist</h5>
                <h4>Rs 17,500 /-</h4>
              </Card.Title>
              <Card.Body className="service_description_card--body">
                We use Temptu brand in this category of Makeup: <br></br>This
                category of Makeup offers following services: <br></br> MINK
                EYELASHES( worth Rs 750), BRIDAL DRAPING, NAIL PAINT , JEWELLERY
                SETTING
              </Card.Body>
              {/* <Card.Footer className="service_description_card--footer">
                <Button variant="outline-dark">View Pictures</Button>
                <Button variant="outline-dark">Enquire</Button>
              </Card.Footer> */}
            </Card>
          </Col>
          <Col className="optional_column_bottom">
            <Image
              fluid
              src={bridalAirBrushMakeupBySeniorImage}
              className="makeupservice_image"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default Makeup;
