import React from "react";
import Header from "../Components/Header";
import Footer from "./Footer";
function Layout(props) {
  return (
    <React.Fragment>
      <Header />
      {props.children}
    </React.Fragment>
  );
}
export default Layout;
