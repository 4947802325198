import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import emailjs from "emailjs-com";

function Enquire() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_drnq093",
        "template_bxi9qgj",
        e.target,
        "user_kDzlBVAbMUoDOwWvXO9UU"
      )
      .then(
        (result) => {
          alert("Your Enquiry has been submitted successfully");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  } //function ends
  return (
    <Container
      style={{
        backgroundColor: "#363945",
        color: "white",
        borderRadius: "5px",
      }}
    >
      <Row className="m-0 m-md-2 p-1 p-md-2">
        <h4
          className="mx-auto"
          style={{ fontFamily: "Cormorant Garamond , serif" }}
        >
          SEND YOUR REQUIREMENTS
        </h4>
      </Row>
      <Row className="m-0 m-md-2 p-1 p-md-2">
        <Col xs={12} sm={12} className="m-0 m-md-2 p-3 p-md-2 mx-auto">
          <Form
            onSubmit={sendEmail}
            style={{ maxWidth: "500px" }}
            className="mx-auto"
          >
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="from_name" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="reply_to" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control type="number" name="number" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Requirement</Form.Label>
              <Form.Control as="textarea" name="message" rows={4} />
            </Form.Group>

            <Form.Group>
              <input
                className="btn btn-outline-light mt-3"
                type="submit"
                value="Submit"
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control type="hidden" name="to_name" value="Kopal Jain" />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
export default Enquire;
